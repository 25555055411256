@keyframes pulse-animation {
  0%{
    transform: scale(0);
    opacity: 1;
  }
  100%{
    transform: scale(1.8,1.8);
    opacity: 0;
  }
}

.pulse {
  animation: pulse-animation 1.3s ease-in-out infinite;
}


@keyframes changeInvert {
  0% {
    filter: hue-rotate(480deg)

  }
  100% {
    filter: hue-rotate(100deg)
  }
}

.hue-invert {
  animation: changeInvert 10s infinite;
}
