@import "../../assets/style/index.scss";
//@import '../../node_modules/tiny-slider/dist/tiny-slider.css';
@import "../../node_modules/aos/dist/aos.css";
@import "../../components/navigation/navigation.scss";
@import "../../components/footer/footer.scss";
@import "../../components/car-card/car-card.scss";
//@import "../../components/cookie-bar/cookie-bar.scss";

main {
  .header-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
      min-height: auto;
    }

    img {
      width: 80px;
      flex: 0 0 80px;

      @media(max-width: 991px) {
        width: 40px;
        flex: 0 0 40px;
      }
    }

    h1 {
      @media(max-width: 991px) {
        font-size: 30px;
      }
    }
  }

  .video-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }
  }

  .features-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }
  }



  .cars-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }

    .details-info {
      width: 250px;
      margin: 0 auto;
      gap: 14px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      display: grid;

      @media(max-width: 991px) {
        width: 100%;
      }
    }
  }

  .offer-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }
  }


  .news-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }
  }

  .divider-section {
    padding: 100px 0;

    @media(max-width: 991px) {
      padding: 50px 0;
    }

    h2 {
      @media(max-width: 991px) {
        font-size: 24px;
      }
    }

    h3 {
      @media(max-width: 991px) {
        font-size: 18px;
      }
    }
  }
}

.lum-lightbox.lum-open {
  z-index: 99999;
}

.lum-close-button {
  cursor: pointer;
}

.lum-lightbox {
  background: rgba(#000000, 0.9);
}
